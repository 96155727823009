//
// Slick Slider
//
.slick-slider {
  position: relative;
  display: block;
  user-select: none;
  touch-action: pan-y;

  .slick-list {
    transform: translateZ(0);
  }

  .slick-track {
    transform: translateZ(0);
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  height: 80% !important;
  margin: 0;
  padding: 0;
  outline: none !important;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: hand;
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  height: 100%;

  &::after {
    display: table;
    clear: both;
    content: "";
  }

  &::before {
    display: table;
    content: "";
  }
}

.slick-loading {
  .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    visibility: hidden;
  }
}

.bottomRotationPanel {
  position: relative;
  margin-top: 1rem;
}

.slick-slide {
  position: relative;
  display: flex !important;
  float: left;
  align-items: center;
  //justify-content: center;
  height: 85%;
  min-height: 1px;
  outline: none !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

[dir="rtl"] {
  .slick-slide {
    float: right;
  }

  .slick-prev {
    right: -25px;
    left: auto;

    &::before {
      content: "→";
    }
  }

  .slick-next {
    right: auto;
    left: -25px;

    &::before {
      content: "←";
    }
  }
}

.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  @include size(20px);
  right: -25px;
  display: block;
  padding: 0;
  cursor: pointer;
  transform: translateY(-50%);
  color: transparent;
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    color: transparent;
    outline: none;
    background: transparent;

    &::before {
      opacity: 1;
    }
  }

  &:hover {
    color: transparent;
    outline: none;
    background: transparent;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    font-family: slick;
    font-size: $font-size-lg;
    content: "→";
    opacity: 0.75;
    color: $white;
  }

  &.slick-disabled:before {
    opacity: 0.25;
  }
}

.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  @include size(20px);
  left: -25px;
  display: block;
  padding: 0;
  cursor: pointer;
  transform: translateY(-50%);
  color: transparent;
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    color: transparent;
    outline: none;
    background: transparent;

    &::before {
      opacity: 1;
    }
  }

  &:hover {
    color: transparent;
    outline: none;
    background: transparent;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    font-family: slick;
    font-size: $font-size-lg;
    content: "←";
    opacity: 0.75;
    color: $white;
  }

  &.slick-disabled:before {
    opacity: 0.25;
  }
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  overflow-y: scroll;
  width: 100%;
  max-height: 30%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 85px;
    height: 85px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    background-color: transparent;

    div {
      &:hover {
        background-color: $md-gray-100;

        div {
          opacity: 1 !important;
          color: $color_storm_dust_approx;
          background-color: $md-gray-100;
        }

        img {
          opacity: 1 !important;
          background-color: $md-gray-100;
        }
      }

      div {
        position: absolute;
        width: 100%;
        opacity: 0;
        color: $white;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: theme-color("primary");
      }

      img {
        padding: 3px;
      }
    }
  }

  .slick-active div {
    cursor: default;
    opacity: 1 !important;
    background-color: theme-color("primary");

    &:hover {
      opacity: 1 !important;
      background-color: theme-color("primary");

      div {
        opacity: 1 !important;
        color: $white;
        background-color: theme-color("primary");
      }

      img {
        opacity: 1 !important;
        background-color: theme-color("primary");
      }
    }

    img {
      opacity: 1 !important;
      background-color: theme-color("primary");
    }
  }
}

.slideshow {
  @include size(100%);
  margin-bottom: 0 !important;
}

.slide-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include size(100%);

  & .button-holder {
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
    opacity: 0;
    z-index: 1000 !important;
  }

  &:hover .button-holder {
    opacity: 1;
  }
}

.slideshow-viewport {
  @include size(100%);
}

.slideshow-container {
  overflow: hidden;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  padding: 20px;
}

.slick-dot-thumbnail {
  width: auto;
  max-width: calc(100% - 8px);
  height: auto;
  max-height: calc(100% - 8px);
  opacity: 1 !important;
}

.slide-internal {
  order: 2;
  width: calc(100% - 80px);
  height: auto;
  cursor: zoom-in; /* Change cursor to indicate zoom functionality */

  &.heightfix {
    width: auto;
    height: 100%;
  }
}

.zoomed-in {
  cursor: zoom-out;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dotted.slick-slider {
  margin-bottom: $spacer * 2;
}
